<template lang="pug">
     v-container

        v-card.pointer(v-for="category in categories" :key="category.id" class="mx-auto mb-1" max-width="400" @click.native="goToCategory(category)")
            v-card-title {{ category.name }}


        v-card.pointer(v-if="selectedProject && selectedProject.motivationalReport"  class="mx-auto mb-1" max-width="400" @click.native="goToMotivationalReport")
            v-card-title Мотивационный отчёт

        v-card(v-if="loading" class="mx-auto" flat max-width="50")
            v-progress-circular(:size="50" color="primary" indeterminate)

        v-card(v-if="error" class="mx-auto" max-width="400")
            v-card-title Что-то пошло не по плану
                v-spacer
                v-icon(color="red") mdi-alert
            v-card-subtitle {{ error }}

</template>


<script>
    import { tradepointService, categoryService, projectService } from '@/_services'
    import { mapState, mapMutations } from "vuex";

    export default {
        props: [ 'id', 'nid', 'pid' ],
        data: () => ({
            loading: false,
            error: null,
            categories: []
        }),

        mounted() {
            if (!this.selectedPoint) this.loadTradePointData()
            else {
                this.setAppBarTitle(this.selectedPoint.address)
            }
            this.loadCategories(),
            projectService.loadProject(this.id).then(data => {
                this.setSelectedProject(data)
            })
        },
        destroyed() {
            this.setSelectedPoint(null)
        },

        computed: {
            ...mapState(['appBarTitle']),
            ...mapState("tradepoint", ["selectedPoint"]),
            ...mapState("project", ["selectedProject"])
        },
        methods: {
            ...mapMutations(['setAppBarTitle']),
            ...mapMutations("tradepoint", ["setSelectedPoint"]),
            ...mapMutations("category", ["setSelectedCategory"]),
            ...mapMutations("project", ["setSelectedProject"]),

            loadTradePointData() {
                this.setAppBarTitle('loading')
                tradepointService.loadPoint(this.pid)
                    .then(data => {
                        this.setSelectedPoint(data)
                        this.setAppBarTitle(data.address)
                    })
                    .catch(error => {
                        this.setSelectedPoint(null)
                        this.setAppBarTitle(error)
                    })
            },

            loadCategories() {
                this.loading = true
                categoryService.getpointcategories(this.pid, this.id)
                    .then(data => {
                        this.categories = data
                        this.loading = false
                    })
                    .catch(error => {
                        this.error = error
                        this.loading = false
                    })
            },

            goToCategory(c) {
                this.setSelectedCategory(c)
                this.$router.push(`/project/${this.id}/network/${this.nid}/tradepoint/${this.pid}/category/${c.id}`)
            },

            goToMotivationalReport() {
                this.$router.push(`/project/${this.id}/network/${this.nid}/tradepoint/${this.pid}/motivationalReport`)
            }
            
        }
    }
</script>